<template>
  <base-layout>
    <template v-slot:title>
      <div class="text-lg">
        パスワードの再設定
      </div>
    </template>
    <div
      class="flex items-center h-full justify-center"
    >
      パスワードの再設定について数分以内にメールでご連絡いたします。
    </div>
  </base-layout>
</template>
<script >

import {defineComponent} from 'vue';

export default defineComponent({
  components: {
    // GSignInButton
  },
})
</script>


